@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&family=Nunito:wght@200;300;400;500;600;700;800&display=swap");
$gray: #b4b4b4;
$blue-dark: #025184;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

body {
  font-family: "Nunito", sans-serif !important;
}

/* Firefox */

* {
  scrollbar-width: auto;
  // scrollbar-color: #C8A366 #ffffff;
}

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 2px solid #ffffff;
  &:hover {
    background-color: #767ffe;
  }
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-1p25 {
  transform: scale(1.25);
}

.icon-1p30 {
  transform: scale(1.3);
}

.icon-1p35 {
  transform: scale(1.35);
}

.icon-1p50 {
  transform: scale(1.5);
}

.icon-2x {
  transform: scale(2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #a3a3a3;
}

.text-green {
  color: #72ee38;
}

.text-blue {
  color: #183972;
}

.text-orange {
  color: #ffb84e;
}

.text-lightblue {
  color: #407bff;
}

.text-pink {
  color: #f86d6d;
}

.text-cyan {
  color: #39c9c1;
}

.text-purple {
  color: #b910bc;
}

.text-yellow {
  color: #e89416;
}

.bg-gray {
  background: #f9f9f9 !important;
}

.bg-green {
  background: #72ee38;
}

.bg-lightgreen {
  background: #e6ffda;
}

.bg-pink {
  background: #f86d6d;
}

.bg-lightpink {
  background: #fff8f8;
}

.bg-blue {
  background: #102abc;
}

.bg-lightblue {
  background: #eaeeff;
}

.bg-cyan {
  background: #39c9c1;
}

.bg-lightcyan {
  background: #ecfffe;
}

.bg-purple {
  background: #b910bc;
}

.bg-lightpurple {
  background: #feddff;
}

.bg-yellow {
  background: #e89416;
}

.bg-lightyellow {
  background: #fff0d9;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mxw-250 {
  max-width: 250px;
}

.w30px {
  width: 30px;
}

.h30px {
  height: 30px;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  // height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #ebeeff;
  z-index: 20;
  .userdrop {
    border: none;
    background: none;
    img {
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .btn-group {
    .dropdown-menu {
      border: none;
      box-shadow: 0px 4px 20px 0px #00000026;
      li {
        padding: 5px 0;
      }
      .img-box {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .text-box {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
      }
      .drop-btn {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: start;
        display: flex;
        align-items: center;
        &:hover {
          color: #16057e;
        }
        .arrow {
          right: 10px;
        }
      }
    }
  }
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  transition: 200ms;
  &:hover {
    background: #0e2074;
    border: 1px solid #0e2074;
    color: #ffffff;
  }
}

.wrapper-box {
  padding-top: calc(62px + 10px);
}

aside {
  width: 100px;
  height: calc(100vh - 62px);
  // background: #FAFAFA;
  // background: red;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  padding: 15px;
  //
  div {
    border-radius: 15px 0 0 15px;
    padding: 10px 0 0 10px;
    text-align: center;
    height: 100%;
    background: rgb(25, 53, 183);
    background: linear-gradient(
      354deg,
      rgba(25, 53, 183, 1) 6%,
      rgba(12, 21, 74, 1) 96%
    );
  }
}

.wrapper {
  transition: 500ms;
  min-height: 100vh;
  // margin-left: calc( 280px + 15px);
  padding-left: calc(280px + 15px);
  // padding-top: 70px;
  padding-top: 66px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
  background: #ffffff;
}

.bg-gray {
  background: #e9e5e6;
}

.grd-btn {
  flex: 1 1 auto;
  padding: 30px;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn {
  // background-image: linear-gradient(to right, #183972 0%, #246b9a 51%, #183972 100%);
  // background-image: linear-gradient(90deg, #0E1F6E 0%, #112EDB 100%);
  background: rgb(26, 113, 167);
  background: linear-gradient(
      90deg,
      rgba(26, 113, 167, 1) 0%,
      rgba(157, 55, 132, 1) 100%
    )
    var(--x, 0) / 130%;
  padding: 10px 20px;
  border: none;
  &:hover {
    --x: 100%;
  }
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

hr {
  margin: 1rem 0;
  // color: #F1F1F1 !important;
  color: #d8d7d7 !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.signin-box {
  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  z-index: -1;
  .loginfram {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -10;
  }
  .top-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 240px;
  }
  .bottom-img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 240px;
  }
}

.aside-form {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      color: #fafafa !important;
      padding-bottom: 20px;
      button {
        border: none;
        padding: 5px;
        // border-radius: 4px;
        border-radius: 12px 0 0 12px;
        background: transparent;
        color: #fafafa !important;
        font-weight: 500;
        font-size: 10px;
        width: 100%;
      }
      &:hover,
      :active {
        transition: 5000ms;
        button {
          background: #fafafa;
          color: #1934b5 !important;
        }
        span {
          color: FAFAFA;
        }
      }
    }
  }
}

// login input css
.login-input {
  width: 100%;
  box-shadow: 0px 0px 8px 0px #00000040;
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
  padding: 16px;
  box-sizing: border-box;
  transition: 0.3s;
  // border-left: 10px solid #454E8F;
}

.login-input:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon .login-input {
  padding-left: 60px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 24px;
  padding-left: 20px;
  padding-right: 10px;
  color: #aaa;
  transition: 0.3s;
  border-right: 1px solid #2d6192;
}

.w-auto {
  width: auto !important;
}

.inputWithIcon .login-input:focus + i {
  color: dodgerBlue;
  border-color: dodgerBlue;
}

.inputWithIcon.inputIconBg .login-input:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

// =====tab btn
.tabs-row {
  .nav-pills {
    display: flex;
    justify-content: space-between;
    .nav-item {
      background: #ffffff;
      width: 48%;
      .nav-link {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2a2a2a !important;
  padding: 8px 20px !important;
  width: 100%;
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 400ms;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
}

.card {
  border-radius: 25px !important;
  .card-box {
    border: none !important;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        font-weight: 400;
        font-size: 16px;
        padding: 8px 20px;
        border-top: 1px solid #e0e0e0;
      }
    }
    .icon-box {
      width: min-content;
      margin: 15px 0 15px 20px;
    }
    .btn-box {
      margin: 20px;
    }
  }
}

.tracking-btn {
  font-weight: 600;
  border: 1px solid #0e2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
  .icon {
    font-size: 28px !important;
    position: absolute;
    right: 40px;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 200ms;
  }
}

.dash-card {
  box-shadow: 0px 2px 15px 0px #0000001a;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}

.line {
  border-left: 1px solid #1934b6;
  padding-left: 10px;
  .icon-box {
    padding: 5px;
    background: #102abc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pie-chart {
  .apexcharts-legend-series {
    display: flex;
    align-items: center;
    padding: 4px 0;
    span {
      font-weight: 500 !important;
      font-size: 14px !important;
    }
    .apexcharts-legend-marker {
      border-radius: 0px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.apexcharts-toolbar {
  display: none !important;
}

//
.normal-tbl {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      text-align: start;
      padding: 10px 8px;
    }
    .tr-bg {
      background: #f6f6f6 !important;
    }
  }
  tr:nth-child(odd) {
    // background-color: #f1eeee;
    background: #fbfbfb;
    // border: 1px solid #E2E2E2;
    border: 1px solid #f3f3f3;
  }
  button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
  }
  .arrow-btn {
    &:hover {
      color: #097ba9;
    }
  }
}

.aside-new {
  transition: 500ms;
  width: 280px;
  height: calc(100vh - 66px);
  background: #fafbfc;
  border-right: 1px solid #e9ebf0;
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  &.scroll-disabled {
    pointer-events: none;
  }
  .dashboard-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 37px 40px 37px;
    a {
      text-decoration: none;
      img {
        min-width: 80px;
      }
    }
    button {
      background: transparent;
      cursor: pointer;
      color: #8b8b8b;
      padding: 0;
      border: none;
      outline: none;
      line-height: 1;
      font-size: 30px;
      &:hover {
        color: #1a71a7;
      }
    }
  }
  strong {
    padding-left: 20px;
    font-weight: 600;
    font-size: 18px;
  }
  // =====new
  ul {
    list-style-type: none;
    padding: 0 20px;
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    li {
      padding-bottom: 8px;
      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }
    }
    .nav-link {
      text-decoration: none;
      height: 44px;
      padding-left: 15px;
      padding-right: 10px;
      margin-top: 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      transition: 300ms;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      &.active,
      &:hover {
        background: #e9f2ff;
        color: #097ba9;
      }
      &.nav-toggle {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-left: 2px solid #000;
          border-bottom: 2px solid #000;
          right: 15px;
          top: calc(50% - 6px);
          transform: rotate(-135deg);
          transition: 300ms;
        }
        &:hover {
          &::before {
            border-left-color: #097ba9;
            border-bottom-color: #097ba9;
          }
        }
        &.active {
          color: #097ba9;
          background-color: #e9f2ff;
          &::before {
            top: calc(50% - 8px);
            transform: rotate(-45deg);
            border-left: 2px solid #097ba9;
            border-bottom: 2px solid #097ba9;
          }
        }
      }
      strong {
        padding-left: 12px;
        pointer-events: none;
      }
    }
    .navmore-link {
      position: relative;
      margin: 0 auto;
      border-radius: 5px;
      display: none;
      &.active {
        display: block;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        padding: 10px 44px;
        font-weight: 600;
        font-size: 16px;
        color: #2a2a2a;
        border-radius: 5px;
        &:hover,
        :active {
          color: #097ba9;
          background: #e9f2ff;
        }
      }
    }
  }
}

.signin-left {
  .img-box {
    width: 100%;
    max-width: 500px;
    z-index: 10;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.signin-right {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .btn-box {
    background: #eeeeee;
    border-radius: 6px;
    padding: 8px;
    button {
      background: transparent;
      border: none;
      padding: 8px;
      border-radius: 5px;
      &:hover,
      :active {
        background: #ffffff;
      }
    }
  }
  .anchor {
    a {
      text-decoration: none;
      color: #055687;
    }
    .line {
      text-decoration: underline;
    }
  }
}

.input-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

.menu-btn {
  background: none;
  border: none;
  &:hover {
    color: #097ba9;
  }
}

.shrink-menu {
  .aside-new {
    transition: 500ms;
    width: 120px;
    .dashboard-logo {
      flex-direction: column;
      button {
        margin-top: 10px;
      }
    }
    .invite-box {
      display: flex;
      justify-content: center;
      span {
        display: none;
      }
    }
    .aside-modelbtn {
      display: flex;
      justify-content: center;
      span,
      .icon {
        display: none;
      }
    }
    ul {
      .nav-link {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        strong {
          display: none;
        }
      }
      word-break: break-all;
    }
    .navmore-link {
      padding: 0px;
      a {
        padding: 10px 5px;
        font-size: 13px;
      }
    }
    .nav-toggle {
      &::before {
        content: "";
        display: none;
      }
    }
  }
  header {
    width: calc(100% - 110px);
    margin-left: calc(110px + 37px);
    padding-left: 37px;
  }
  .wrapper {
    padding-left: calc(100px + 37px);
    transition: 500ms;
  }
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid #ffffff;
  }
}

.normal-btn {
  background: none;
  border: 1px solid black;
  &:hover {
    background: rgb(14, 31, 110);
    background: linear-gradient(
      90deg,
      rgba(14, 31, 110, 1) 0%,
      rgba(17, 46, 219, 1) 100%
    );
    color: #fafafa;
    border: 1px solid #1734b8 !important;
  }
}

.eye-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 40px;
  top: 30%;
  color: #aaaaaa;
}

.border-btn {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  color: #0d1a5a;
  &:hover {
    color: #097ba9;
  }
}
.ck.ck-powered-by {
  display: none;
}
/* Add this CSS to your stylesheet or a <style> tag in your component */
.image-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  margin: 0 auto;
  width: 100%;
  object-fit: contain;
}

.image-container .hover-buttons {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.image-container .hover-buttons .top-left,
.image-container .hover-buttons .bottom-right,
.image-container .hover-buttons .close-btn,
.image-container .hover-buttons .left-align,
.image-container .hover-buttons .right-align,
.image-container .hover-buttons .center-align,
.image-container .hover-buttons .center-resize,
.image-container .hover-buttons .alt-modify {
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container .hover-buttons .top-left {
  top: 5px;
  left: 5px;
}

.image-container .hover-buttons .bottom-right {
  bottom: 5px;
  right: 5px;
}

.image-container .hover-buttons .close-btn {
  top: 5px;
  right: 5px;
}

.image-container .hover-buttons .left-align {
  top: 0px;
  left: calc(50% - 20px);
}

.image-container .hover-buttons .right-align {
  bottom: 5px;
  left: calc(50% - 20px);
}

.image-container .hover-buttons .center-resize {
  top: calc(50% - 20px);
  left: calc(50% - 70px); /* Moved slightly to the left */
}

.image-container .hover-buttons .center-align {
  top: calc(50% - 20px);
  left: calc(50% - 20px); /* Positioned right next to the center-align button */
}
.image-container .hover-buttons .alt-modify {
  top: calc(50% - 20px);
  left: calc(50% + 30px); /* Positioned right next to the center-align button */
}

.image-container:hover .hover-buttons {
  display: block;
}

.toc-container {
  padding: 10px;
}

.toc-item {
  display: block;
  padding: 5px 0;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.toc-item:hover {
  color: darkblue;
}
// table {
//   width: 100%;
//   border-collapse: collapse;
// }

// td {
//   border: 1px solid #ccc;
//   padding: 8px;
//   // position: relative;
//   // vertical-align: top; /* Ensure text is aligned at the top */
//   word-break: break-word; /* Ensure long words break correctly */
//   min-width: 50px; /* Set a minimum width for cells */
// }

// .col-resizer,
// .row-resizer {
//   position: absolute;
//   background: #ccc;
// }

// .col-resizer {
//   width: 5px;
//   height: 100%;
//   right: 0;
//   top: 0;
//   cursor: col-resize;
//   user-select: none;
// }

// .row-resizer {
//   height: 5px;
//   width: 100%;
//   bottom: 0;
//   left: 0;
//   cursor: row-resize;
//   user-select: none;
// }

table {
  border-collapse: collapse;
  border-spacing: 0px;
}
td {
  border: 2px solid black;
  padding: 0;
  margin: 0px;
  overflow: auto;
}

td .td-content {
  resize: both;
  overflow: auto;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: 1px solid black;
  display: block;
  border: 0;
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 20px;
}
.resizable-td {
  position: relative; /* Needed for absolute positioning of handles */
}

.resize-handle {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #ccc;
  cursor: nwse-resize; /* Cursor for resizing */
}

.resize-handle.bottom-right {
  right: 0;
  bottom: 0;
}

.resize-handle.top-left {
  top: 0;
  left: 0;
}

.form-group-container {
  display: flex;
  flex-wrap: wrap;
  // gap: 20px;
  column-gap: 20px;
}
.vision {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  background: none;
  .icon {
    color: #515456;
  }
}

// Upload Demo//
.uploader {
  display: block;
  width: 80px;
  height: 80px;
  label {
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
    background: #f2f7ff;
    border-radius: 50%;
    transition: all 0.2s ease;
    user-select: none;
    width: 80px;
    height: 80px;
  }
  #start {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all 0.2s ease-in-out;
    }
  }
  #response {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    #messages {
      margin-bottom: 0.5rem;
    }
  }
  #file-image {
    display: inline;
    // margin: 0 auto .5rem auto;
    width: auto;
    height: auto;
    max-width: 80px;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    &.hidden {
      display: none;
    }
  }
  #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
  }
  input[type="file"] {
    display: none;
  }
}

.tools-card {
  .card-box {
    .card {
      border-radius: 15px;
      // border: none;
      // background: rgb(153, 57, 133);
      // background: linear-gradient(0deg, rgba(153, 57, 133, 1) 0%, rgba(30, 111, 166, 1) 100%);
      transition: transform 0.5s;
      &:hover {
        // transform: scale(1.06);
        background: rgb(153, 57, 133);
        background: linear-gradient(
          0deg,
          rgba(153, 57, 133, 1) 0%,
          rgba(30, 111, 166, 1) 100%
        );
        color: #fff;
        border: none;
      }
      transition: 1000ms;
      .card-body {
        .icon {
          font-size: 34px;
        }
        .card-title {
          font-size: 28px;
          font-weight: 700;
        }
        .card-text {
          font-size: 18px;
        }
      }
    }
  }
}
.text-editor {
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  ol {
    padding-left: 2rem;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  p {
    padding: 0.8em 0;
  }
}
// .waitlist-tbl {
//   width: 100%;
//   overflow: auto;
// }
// @media (max-width: 768px) {
//   .waitlist-tbl {
//     overflow: visible;
//   }
// }
