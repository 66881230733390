@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&family=Nunito:wght@200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

*, *::before, *::after {
  box-sizing: border-box;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

body {
  font-family: "Nunito", sans-serif !important;
}

/* Firefox */
* {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(219, 213, 213, 0.7882352941);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #767ffe;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-1p25 {
  transform: scale(1.25);
}

.icon-1p30 {
  transform: scale(1.3);
}

.icon-1p35 {
  transform: scale(1.35);
}

.icon-1p50 {
  transform: scale(1.5);
}

.icon-2x {
  transform: scale(2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #a3a3a3;
}

.text-green {
  color: #72ee38;
}

.text-blue {
  color: #183972;
}

.text-orange {
  color: #ffb84e;
}

.text-lightblue {
  color: #407bff;
}

.text-pink {
  color: #f86d6d;
}

.text-cyan {
  color: #39c9c1;
}

.text-purple {
  color: #b910bc;
}

.text-yellow {
  color: #e89416;
}

.bg-gray {
  background: #f9f9f9 !important;
}

.bg-green {
  background: #72ee38;
}

.bg-lightgreen {
  background: #e6ffda;
}

.bg-pink {
  background: #f86d6d;
}

.bg-lightpink {
  background: #fff8f8;
}

.bg-blue {
  background: #102abc;
}

.bg-lightblue {
  background: #eaeeff;
}

.bg-cyan {
  background: #39c9c1;
}

.bg-lightcyan {
  background: #ecfffe;
}

.bg-purple {
  background: #b910bc;
}

.bg-lightpurple {
  background: #feddff;
}

.bg-yellow {
  background: #e89416;
}

.bg-lightyellow {
  background: #fff0d9;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mxw-250 {
  max-width: 250px;
}

.w30px {
  width: 30px;
}

.h30px {
  height: 30px;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #ebeeff;
  z-index: 20;
}
.navbar .userdrop {
  border: none;
  background: none;
}
.navbar .userdrop img {
  border-radius: 50%;
  margin-right: 5px;
}
.navbar .btn-group .dropdown-menu {
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
}
.navbar .btn-group .dropdown-menu li {
  padding: 5px 0;
}
.navbar .btn-group .dropdown-menu .img-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.navbar .btn-group .dropdown-menu .img-box img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.navbar .btn-group .dropdown-menu .text-box {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.navbar .btn-group .dropdown-menu .drop-btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
}
.navbar .btn-group .dropdown-menu .drop-btn:hover {
  color: #16057e;
}
.navbar .btn-group .dropdown-menu .drop-btn .arrow {
  right: 10px;
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  transition: 200ms;
}
.logout-btn-line:hover {
  background: #0e2074;
  border: 1px solid #0e2074;
  color: #ffffff;
}

.wrapper-box {
  padding-top: 72px;
}

aside {
  width: 100px;
  height: calc(100vh - 62px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  padding: 15px;
}
aside div {
  border-radius: 15px 0 0 15px;
  padding: 10px 0 0 10px;
  text-align: center;
  height: 100%;
  background: rgb(25, 53, 183);
  background: linear-gradient(354deg, rgb(25, 53, 183) 6%, rgb(12, 21, 74) 96%);
}

.wrapper {
  transition: 500ms;
  min-height: 100vh;
  padding-left: 295px;
  padding-top: 66px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
  background: #ffffff;
}

.bg-gray {
  background: #e9e5e6;
}

.grd-btn {
  flex: 1 1 auto;
  padding: 30px;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn {
  background: rgb(26, 113, 167);
  background: linear-gradient(90deg, rgb(26, 113, 167) 0%, rgb(157, 55, 132) 100%) var(--x, 0)/130%;
  padding: 10px 20px;
  border: none;
}
.grd-btn:hover {
  --x: 100%;
}

::-moz-placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

hr {
  margin: 1rem 0;
  color: #d8d7d7 !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.signin-box {
  width: 100%;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  z-index: -1;
}
.signin-box .loginfram {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}
.signin-box .top-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 240px;
}
.signin-box .bottom-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 240px;
}

.aside-form ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.aside-form ul li {
  color: #fafafa !important;
  padding-bottom: 20px;
}
.aside-form ul li button {
  border: none;
  padding: 5px;
  border-radius: 12px 0 0 12px;
  background: transparent;
  color: #fafafa !important;
  font-weight: 500;
  font-size: 10px;
  width: 100%;
}
.aside-form ul li:hover,
.aside-form ul li :active {
  transition: 5000ms;
}
.aside-form ul li:hover button,
.aside-form ul li :active button {
  background: #fafafa;
  color: #1934b5 !important;
}
.aside-form ul li:hover span,
.aside-form ul li :active span {
  color: FAFAFA;
}

.login-input {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2509803922);
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
  padding: 16px;
  box-sizing: border-box;
  transition: 0.3s;
}

.login-input:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon .login-input {
  padding-left: 60px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 24px;
  padding-left: 20px;
  padding-right: 10px;
  color: #aaa;
  transition: 0.3s;
  border-right: 1px solid #2d6192;
}

.w-auto {
  width: auto !important;
}

.inputWithIcon .login-input:focus + i {
  color: dodgerBlue;
  border-color: dodgerBlue;
}

.inputWithIcon.inputIconBg .login-input:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.tabs-row .nav-pills {
  display: flex;
  justify-content: space-between;
}
.tabs-row .nav-pills .nav-item {
  background: #ffffff;
  width: 48%;
}
.tabs-row .nav-pills .nav-item .nav-link {
  font-weight: 700;
  font-size: 20px;
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2a2a2a !important;
  padding: 8px 20px !important;
  width: 100%;
}
.nav-item .nav-link:hover {
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%) !important;
  border: 1px solid #112ed8 !important;
  color: #ffffff !important;
  transition: 400ms;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
}

.card {
  border-radius: 25px !important;
}
.card .card-box {
  border: none !important;
}
.card .card-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.card .card-box ul li {
  font-weight: 400;
  font-size: 16px;
  padding: 8px 20px;
  border-top: 1px solid #e0e0e0;
}
.card .card-box .icon-box {
  width: -moz-min-content;
  width: min-content;
  margin: 15px 0 15px 20px;
}
.card .card-box .btn-box {
  margin: 20px;
}

.tracking-btn {
  font-weight: 600;
  border: 1px solid #0e2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
}
.tracking-btn .icon {
  font-size: 28px !important;
  position: absolute;
  right: 40px;
}
.tracking-btn:hover {
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%) !important;
  border: 1px solid #112ed8 !important;
  color: #ffffff !important;
  transition: 200ms;
}

.dash-card {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1019607843);
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}

.line {
  border-left: 1px solid #1934b6;
  padding-left: 10px;
}
.line .icon-box {
  padding: 5px;
  background: #102abc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart .apexcharts-legend-series {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.pie-chart .apexcharts-legend-series span {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.pie-chart .apexcharts-legend-series .apexcharts-legend-marker {
  border-radius: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.normal-tbl table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.normal-tbl table td,
.normal-tbl table th {
  text-align: start;
  padding: 10px 8px;
}
.normal-tbl table .tr-bg {
  background: #f6f6f6 !important;
}
.normal-tbl tr:nth-child(odd) {
  background: #fbfbfb;
  border: 1px solid #f3f3f3;
}
.normal-tbl button {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}
.normal-tbl .arrow-btn:hover {
  color: #097ba9;
}

.aside-new {
  transition: 500ms;
  width: 280px;
  height: calc(100vh - 66px);
  background: #fafbfc;
  border-right: 1px solid #e9ebf0;
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}
.aside-new.scroll-disabled {
  pointer-events: none;
}
.aside-new .dashboard-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 37px 40px 37px;
}
.aside-new .dashboard-logo a {
  text-decoration: none;
}
.aside-new .dashboard-logo a img {
  min-width: 80px;
}
.aside-new .dashboard-logo button {
  background: transparent;
  cursor: pointer;
  color: #8b8b8b;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 30px;
}
.aside-new .dashboard-logo button:hover {
  color: #1a71a7;
}
.aside-new strong {
  padding-left: 20px;
  font-weight: 600;
  font-size: 18px;
}
.aside-new ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.aside-new ul li {
  padding-bottom: 8px;
}
.aside-new ul li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}
.aside-new ul .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.aside-new ul .nav-link.active, .aside-new ul .nav-link:hover {
  background: #e9f2ff;
  color: #097ba9;
}
.aside-new ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
  transition: 300ms;
}
.aside-new ul .nav-link.nav-toggle:hover::before {
  border-left-color: #097ba9;
  border-bottom-color: #097ba9;
}
.aside-new ul .nav-link.nav-toggle.active {
  color: #097ba9;
  background-color: #e9f2ff;
}
.aside-new ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  border-left: 2px solid #097ba9;
  border-bottom: 2px solid #097ba9;
}
.aside-new ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
}
.aside-new ul .navmore-link {
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  display: none;
}
.aside-new ul .navmore-link.active {
  display: block;
}
.aside-new ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 44px;
  font-weight: 600;
  font-size: 16px;
  color: #2a2a2a;
  border-radius: 5px;
}
.aside-new ul .navmore-link a:hover,
.aside-new ul .navmore-link a :active {
  color: #097ba9;
  background: #e9f2ff;
}

.signin-left .img-box {
  width: 100%;
  max-width: 500px;
  z-index: 10;
}
.signin-left .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.signin-right {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.signin-right .btn-box {
  background: #eeeeee;
  border-radius: 6px;
  padding: 8px;
}
.signin-right .btn-box button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 5px;
}
.signin-right .btn-box button:hover,
.signin-right .btn-box button :active {
  background: #ffffff;
}
.signin-right .anchor a {
  text-decoration: none;
  color: #055687;
}
.signin-right .anchor .line {
  text-decoration: underline;
}

.input-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

::-moz-placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

.menu-btn {
  background: none;
  border: none;
}
.menu-btn:hover {
  color: #097ba9;
}

.shrink-menu .aside-new {
  transition: 500ms;
  width: 120px;
}
.shrink-menu .aside-new .dashboard-logo {
  flex-direction: column;
}
.shrink-menu .aside-new .dashboard-logo button {
  margin-top: 10px;
}
.shrink-menu .aside-new .invite-box {
  display: flex;
  justify-content: center;
}
.shrink-menu .aside-new .invite-box span {
  display: none;
}
.shrink-menu .aside-new .aside-modelbtn {
  display: flex;
  justify-content: center;
}
.shrink-menu .aside-new .aside-modelbtn span,
.shrink-menu .aside-new .aside-modelbtn .icon {
  display: none;
}
.shrink-menu .aside-new ul {
  word-break: break-all;
}
.shrink-menu .aside-new ul .nav-link {
  display: flex;
  justify-content: center;
  padding-left: 10px;
}
.shrink-menu .aside-new ul .nav-link strong {
  display: none;
}
.shrink-menu .aside-new .navmore-link {
  padding: 0px;
}
.shrink-menu .aside-new .navmore-link a {
  padding: 10px 5px;
  font-size: 13px;
}
.shrink-menu .aside-new .nav-toggle::before {
  content: "";
  display: none;
}
.shrink-menu header {
  width: calc(100% - 110px);
  margin-left: 147px;
  padding-left: 37px;
}
.shrink-menu .wrapper {
  padding-left: 137px;
  transition: 500ms;
}
.shrink-menu *::-webkit-scrollbar {
  width: 10px;
}
.shrink-menu *::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
}

.normal-btn {
  background: none;
  border: 1px solid black;
}
.normal-btn:hover {
  background: rgb(14, 31, 110);
  background: linear-gradient(90deg, rgb(14, 31, 110) 0%, rgb(17, 46, 219) 100%);
  color: #fafafa;
  border: 1px solid #1734b8 !important;
}

.eye-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 40px;
  top: 30%;
  color: #aaaaaa;
}

.border-btn {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  color: #0d1a5a;
}
.border-btn:hover {
  color: #097ba9;
}

.ck.ck-powered-by {
  display: none;
}

/* Add this CSS to your stylesheet or a <style> tag in your component */
.image-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  margin: 0 auto;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.image-container .hover-buttons {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.image-container .hover-buttons .top-left,
.image-container .hover-buttons .bottom-right,
.image-container .hover-buttons .close-btn,
.image-container .hover-buttons .left-align,
.image-container .hover-buttons .right-align,
.image-container .hover-buttons .center-align,
.image-container .hover-buttons .center-resize,
.image-container .hover-buttons .alt-modify {
  position: absolute;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container .hover-buttons .top-left {
  top: 5px;
  left: 5px;
}

.image-container .hover-buttons .bottom-right {
  bottom: 5px;
  right: 5px;
}

.image-container .hover-buttons .close-btn {
  top: 5px;
  right: 5px;
}

.image-container .hover-buttons .left-align {
  top: 0px;
  left: calc(50% - 20px);
}

.image-container .hover-buttons .right-align {
  bottom: 5px;
  left: calc(50% - 20px);
}

.image-container .hover-buttons .center-resize {
  top: calc(50% - 20px);
  left: calc(50% - 70px); /* Moved slightly to the left */
}

.image-container .hover-buttons .center-align {
  top: calc(50% - 20px);
  left: calc(50% - 20px); /* Positioned right next to the center-align button */
}

.image-container .hover-buttons .alt-modify {
  top: calc(50% - 20px);
  left: calc(50% + 30px); /* Positioned right next to the center-align button */
}

.image-container:hover .hover-buttons {
  display: block;
}

.toc-container {
  padding: 10px;
}

.toc-item {
  display: block;
  padding: 5px 0;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.toc-item:hover {
  color: darkblue;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

td {
  border: 2px solid black;
  padding: 0;
  margin: 0px;
  overflow: auto;
}

td .td-content {
  resize: both;
  overflow: auto;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: 1px solid black;
  display: block;
  border: 0;
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 20px;
}

.resizable-td {
  position: relative; /* Needed for absolute positioning of handles */
}

.resize-handle {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #ccc;
  cursor: nwse-resize; /* Cursor for resizing */
}

.resize-handle.bottom-right {
  right: 0;
  bottom: 0;
}

.resize-handle.top-left {
  top: 0;
  left: 0;
}

.form-group-container {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
}

.vision {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  background: none;
}
.vision .icon {
  color: #515456;
}

.uploader {
  display: block;
  width: 80px;
  height: 80px;
}
.uploader label {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  background: #f2f7ff;
  border-radius: 50%;
  transition: all 0.2s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 80px;
  height: 80px;
}
.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #start.hidden {
  display: none;
}
.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #response.hidden {
  display: none;
}
.uploader #response #messages {
  margin-bottom: 0.5rem;
}
.uploader #file-image {
  display: inline;
  width: auto;
  height: auto;
  max-width: 80px;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.uploader #file-image.hidden {
  display: none;
}
.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}
.uploader #notimage.hidden {
  display: none;
}
.uploader input[type=file] {
  display: none;
}

.tools-card .card-box .card {
  border-radius: 15px;
  transition: transform 0.5s;
  transition: 1000ms;
}
.tools-card .card-box .card:hover {
  background: rgb(153, 57, 133);
  background: linear-gradient(0deg, rgb(153, 57, 133) 0%, rgb(30, 111, 166) 100%);
  color: #fff;
  border: none;
}
.tools-card .card-box .card .card-body .icon {
  font-size: 34px;
}
.tools-card .card-box .card .card-body .card-title {
  font-size: 28px;
  font-weight: 700;
}
.tools-card .card-box .card .card-body .card-text {
  font-size: 18px;
}

.text-editor a {
  color: #0d6efd;
  text-decoration: underline;
}
.text-editor a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.text-editor h1 {
  font-size: 2.5rem;
}
.text-editor h2 {
  font-size: 2rem;
}
.text-editor h3 {
  font-size: 1.75rem;
}
.text-editor h4 {
  font-size: 1.5rem;
}
.text-editor h5 {
  font-size: 1.25rem;
}
.text-editor h6 {
  font-size: 1rem;
}
.text-editor h1,
.text-editor h2,
.text-editor h3,
.text-editor h4,
.text-editor h5,
.text-editor h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.text-editor ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.text-editor ul li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.text-editor ol {
  padding-left: 2rem;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.text-editor ol li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.text-editor p {
  padding: 0.8em 0;
}/*# sourceMappingURL=index.css.map */